export const setupHeartbeats = async () => {
  if (
    document.querySelector("body")?.dataset?.controllerAction ===
    "projects#show"
  ) {
    console.log("Running setupHeartbeats")
    if (document) {
      const heartbeats = document.querySelectorAll(
        "[data-heartbeat-checked=false]",
      )
      for (const heartbeat of heartbeats) {
        heartbeat.dataset.heartbeatChecked = "true"
      }
      for (const heartbeat of heartbeats) {
        const response = await fetch(
          `/api/heartbeats/${heartbeat.dataset.heartbeatId}`,
        )
        const json = await response.json()
        if (json) {
          if (["pending", "down", "up", "paused"].includes(json.status)) {
            heartbeat
              .querySelector(`[data-status=loading`)
              ?.classList.toggle("hidden")
            heartbeat
              .querySelector(`[data-status=${json.status}]`)
              ?.classList.toggle("hidden")
          }
          if (json.url) {
            heartbeat.href = json.url
          }
        }
      }
    }
  }
}
